<template>
  <div id="app">
    <LayoutHeader/>
    <div class="content" v-if="isProduct">
      <router-view/>
    </div>
    <LayoutFooter v-if="isProduct" :info="sidebarInfo"/>
    <Sidebar v-if="sidebarInfo.isOpen===1" :info="sidebarInfo"/>
  </div>
</template>

<script>
import LayoutHeader from "../common/layoutHeader.vue";
import LayoutFooter from "../common/layoutFooter.vue";
import Sidebar from "../common/sidebar";
import {getProductBasicInfo, getProductSideBar, getTdkList} from "@/api/common/index";
import * as dd from 'dingtalk-jsapi';

// const tdk = require("@/utils/modulePath.js");
export default {
  name: "layout",
  components: {LayoutHeader, LayoutFooter, Sidebar},
  data() {
    return {
      sidebarInfo: {},
      isProduct: false,
      tdkList: []
    };
  },
  created() {
    this.getProductBasicInfoFun();
  },
  mounted() {

  },
  watch: {
    $route(to) {
      this.setTitleDingtalk(to, this.tdkList);
    }
  },
  methods: {
    getTdkListFun(productId) {
      let params = {
        productId: productId
      };
      getTdkList(params).then((res) => {
        this.isProduct = true;
        if (res.success) {
          let list = this.getTdkLIst(res.data.list);
          this.tdkList = list;
          this.setTitleDingtalk(this.$route, this.tdkList);
          this.$store.dispatch("setTdkFun", list);
        }
      });
    },
    getTdkLIst(list) {
      let tdkList = [];
      for (let i of list) {
        tdkList.push({
          path: i.path,
          metaInfo: {
            title: i.title,
            meta: [
              {
                name: "keywords",
                content: i.keywords ? i.keywords.join(",") : ""
              },
              {
                name: "description",
                content: i.description
              }
            ]
          }
        });
      }
      return tdkList;
    },
    getProductBasicInfoFun() {
      let host = window.location.host;
      if (host.includes("localhost") || host.includes("172.19.101.87:8080")) {
        host = "yi.onlinecps.cn";
      }
      let params = {
        domain: host
      };
      getProductBasicInfo(params).then((res) => {
        if (res.success) {
          let openModule = [];
          for (let i of res.data.openModule) {
            openModule.push(i.moduleType);
          }
          res.data.openModule = openModule;
          this.getProductSideBarFun(res.data);
          // this.setIcon(res.data);
          this.$store.dispatch("setProductBasicInfoFun", res.data);
          // 查询TDK信息
          this.getTdkListFun(res.data.productId);
        } else {
          this.isProduct = true;
          if (this.$route.path !== "/notFound") {
            this.$router.replace({
              path: '/notFount',
            });
          }
        }
      }).catch(() => {
        this.isProduct = true;
      });
    },
    setTitleDingtalk(to, tdk) {
      // 钉钉内打开页面title不变化问题特殊处理
      if (dd.ios || dd.android) {
        let title = "";
        for (let i of tdk) {
          if (i.path === to.path) {
            title = i.metaInfo.title;
            break;
          }
        }
        dd.ready(function () {
          dd.biz.navigation.setTitle({
            title: title
          });
        });
      }
    },
    setIcon(data) {
      let link = document.querySelector("link[rel*='icon']") || document.createElement('link');
      link.type = 'image/x-icon';
      link.rel = 'shortcut icon';
      link.href = data.logo;
      document.getElementsByTagName('head')[0].appendChild(link);
    },
    getProductSideBarFun(data) {
      let params = {
        productId: data.productId
      };
      getProductSideBar(params).then((res) => {
        if (res.success) {
          this.sidebarInfo = res.data;
        }
      });
    },
  }
};
</script>

<style scoped lang="scss">
#app {
  .content {
    margin: 0 auto;
    max-width: 3000px;
    width: 100%;
  }
}
</style>
