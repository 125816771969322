import Paging from "./paging";
import Message from "./message";
import introduce from "./introduce";
import Details from "./details";
import Input from "./input";
import Confirm from "./confirm";
import Crumbs from "./crumbs";
import Nothing from "./nothing";
import Button from "./button/button"
import Djbutton from "./button/dj-button"

function plugins(Vue) {
    Vue.component("dj-paging", Paging);
    Vue.component("dj-crumbs", Crumbs);
    Vue.component("Introduce", introduce);
    Vue.component("Details", Details);
    Vue.component("dj-input", Input);
    Vue.component("Button", Button);
    Vue.component("dj-button", Djbutton);
    Vue.component("Nothing", Nothing);
    Vue.prototype.$message = function (message, callback) {
        // 继承Message组件
        const MessageConstructor = Vue.extend(Message);
        // 创建一个新元素
        const messageWrap = document.createElement('div');
        // 实例化Message组件，并将组件挂在到新创建的messageWrap元素上
        const messageInstace = new MessageConstructor({
            el: messageWrap
        });
        document.body.appendChild(messageInstace.$el);
        // 显示组件内容
        if (message) {
            messageInstace.text = message;
        }
        if (typeof callback === "function") {
            setTimeout(() => {
                callback();
            }, 1500);
        }
        messageInstace.show = true;
    };
    Vue.prototype.$confirm = function (config) {
        const ConfirmConstructor = Vue.extend(Confirm);
        const ConfirmWrap = document.createElement('div');
        const confirmInstace = new ConfirmConstructor({
            el: ConfirmWrap
        });
        document.body.appendChild(confirmInstace.$el);
        Vue.nextTick(() => {
            confirmInstace.config = config;
            confirmInstace.show = true;
        });
    };
}

export default plugins;
