// 这个文件在vue.config.js也需要用到，所以用module.exports导出
// path要与页面路由保持一致,这个非常重要
// v1.1 tdk改为从后端获取，这个文件只给打包的时候用

const path = {
    productCenterIndex: {
        path: "/"
    },
    productFreeUse: {
        path: '/product/freeUse'
    },
    example: {
        path: '/example/list'
    },
    exampleDetails: {
        path: '/example/details'
    },
    industryClass: {
        path: '/industryClass/list'
    },
    industryClassDetails: {
        path: '/industryClass/details'
    },
    marketActivity: {
        path: '/marketActivity/list'
    },
    marketActivityDetails: {
        path: '/marketActivity/details'
    },
    productPrice: {
        path: '/productPrice'
    },
    userServiceQuestion: {
        path: '/userService/question'
    },
    userServiceQuestionDetails: {
        path: '/userService/question/details'
    },
    benchmarkExample: {
        path: '/benchmarkExample'
    },
    benchmarkStory: {
        path: '/benchmarkExample/story'
    }
};
module.exports = path;
