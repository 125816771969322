<template>
  <div class="paging" v-if="total>0">
    <div class="box">
      <p class="btn_pn btn_prev" :class="{ btn_dis: currentPage === 1}" @click="onPagerPrev">
        <i class="font_family icon-icon_previous"></i>
        <span>上一页</span>
      </p>
      <ul class="pager" @click="onPagerClick">
        <li v-if="pageCount > 0" :class="{ active: currentPage === 1}">1</li>
        <li v-if="showPrevMore" class="more quickprev">...</li>
        <li v-for="pager in pagers" :key="pager" :class="{ active: currentPage === pager}">{{ pager }}</li>
        <li v-if="showNextMore" class="more quicknext">...</li>
        <li v-if="pageCount > 1" :class="{ active: currentPage === pageCount }">{{ pageCount }}</li>
      </ul>
      <p class="mob_pager">
        <label>{{ currentPage }}</label>/{{ pageCount }}
      </p>
      <p class="btn_pn btn_next" :class="{ btn_dis: currentPage === pageCount}" @click="onPagerNext">
        <i class="font_family icon-icon_next"></i>
        <span>下一页</span>
      </p>
    </div>
  </div>
</template>

<script>
export default {
  name: "paging",
  props: {
    pageNumber: {
      type: Number,
      default: 1
    }, // 当前页
    pageSize: Number, // 每页显示条目
    // pageCount: Number, // 总页数
    total: {
      type: Number,
      default: 0
    },  // 总条数
    pagerCount: {
      type: Number,
      default: 7
    },  // 页码按钮的数量
    top: {
      type: Number,
      default: 0
    },
  },
  data() {
    return {
      currentPage:1,
      showPrevMore: false,
      showNextMore: false,
      pageCount: 0,
    };
  },
  computed: {
    pagers: function () {
      const pagerCount = this.pagerCount;
      const halfPagerCount = (pagerCount - 1) / 2;

      const currentPage = Number(this.currentPage);
      const pageCount = Number(this.pageCount);

      let showPrevMore = false;
      let showNextMore = false;

      if (pageCount > pagerCount) {
        if (currentPage > pagerCount - halfPagerCount) {
          showPrevMore = true;
        }

        if (currentPage < pageCount - halfPagerCount) {
          showNextMore = true;
        }
      }

      const array = [];

      if (showPrevMore && !showNextMore) {
        const startPage = pageCount - (pagerCount - 2);
        for (let i = startPage; i < pageCount; i++) {
          array.push(i);
        }
      } else if (!showPrevMore && showNextMore) {
        for (let i = 2; i < pagerCount; i++) {
          array.push(i);
        }
      } else if (showPrevMore && showNextMore) {
        const offset = Math.floor(pagerCount / 2) - 1;
        for (let i = currentPage - offset; i <= currentPage + offset; i++) {
          array.push(i);
        }
      } else {
        for (let i = 2; i < pageCount; i++) {
          array.push(i);
        }
      }

      // eslint-disable-next-line vue/no-side-effects-in-computed-properties
      this.showPrevMore = showPrevMore;
      // eslint-disable-next-line vue/no-side-effects-in-computed-properties
      this.showNextMore = showNextMore;

      return array;
    }
  },
  watch: {
    pageNumber() {
      this.currentPage=this.pageNumber
    },
    total() {
      this.pageCount = parseInt((this.total + this.pageSize - 1) / this.pageSize);
    }
  },
  created() {
    // this.pageCount = parseInt((this.total + this.pageSize - 1) / this.pageSize);
  },
  methods: {
    onPagerPrev() {
      if (this.currentPage === 1) {
        return;
      }
      this.currentPage--;
      this.$emit('current-change', this.currentPage);
      if (this.top !== 0) {
        window.scroll({
          top: this.top,
          behavior: 'smooth'
        });
      }
    },
    onPagerNext() {
      if (this.currentPage === this.pageCount) {
        return;
      }
      this.currentPage++;
      this.$emit('current-change', this.currentPage);
      if (this.top !== 0) {
        window.scroll({
          top: this.top,
          behavior: 'smooth'
        });
      }
    },
    onPagerClick(event) {
      const target = event.target;
      if (target.tagName === 'UL') {
        return;
      }

      let newPage = Number(event.target.textContent);
      const pageCount = this.pageCount;
      const currentPage = this.currentPage;
      const pagerCountOffset = this.pagerCount - 2;

      if (target.className.indexOf('more') !== -1) {
        if (target.className.indexOf('quickprev') !== -1) {
          newPage = currentPage - pagerCountOffset;
        } else if (target.className.indexOf('quicknext') !== -1) {
          newPage = currentPage + pagerCountOffset;
        }
      }

      /* istanbul ignore if */
      if (!isNaN(newPage)) {
        if (newPage < 1) {
          newPage = 1;
        }

        if (newPage > pageCount) {
          newPage = pageCount;
        }
      }

      if (newPage !== currentPage) {
        this.$emit('current-change', newPage);
        this.currentPage = newPage;
        if (this.top !== 0) {
          window.scroll({
            top: this.top,
            behavior: 'smooth'
          });
        }
      }
    },
  }
};
</script>

<style scoped lang="scss">
.paging {
  padding: 40px 60px 60px 60px;
  .box {
    display: flex;
    align-items: center;
    justify-content: space-between;
    .btn_pn {
      width: 170px;
      height: 66px;
      background: #FFFFFF;
      border-radius: 4px;
      border: 1px solid #CCCCCC;
      display: flex;
      align-items: center;
      justify-content: center;
      i {
        display: none;
      }
      span {
        font-size: 24px;
        color: #333333;
      }
    }
    .btn_dis {
      background-color: #F5F5F5;
      span {
        color: #C0C4CC;
      }
    }
    .mob_pager {
      font-size: 28px;
      color: #354052;
      label {
        color: #0064EB;
      }
    }
    .pager {
      display: none;
      li {
      }
      .active {
      }
    }
  }
}
@media (min-width: 750px) and (max-width: 960px) {
  .paging {
    padding: 40px 60px 60px 60px;
    .box {
      display: flex;
      align-items: center;
      justify-content: space-between;
      .btn_pn {
        width: 120px;
        height: 48px;
        background: #FFFFFF;
        border-radius: 4px;
        border: 1px solid #CCCCCC;
        display: flex;
        align-items: center;
        justify-content: center;
        i {
          display: none;
        }
        span {
          font-size: 16px;
          color: #333333;
        }
      }
      .btn_dis {
        background-color: #F5F5F5;
        span {
          color: #C0C4CC;
        }
      }
      .mob_pager {
        font-size: 18px;
        color: #354052;
        label {
          color: #0064EB;
        }
      }
      .pager {
        display: none;
        li {
        }
        .active {
        }
      }
    }
  }
}
@media (min-width: 961px) {
  .paging {
    display: flex;
    justify-content: flex-end;
    width: 1200px;
    margin: 0 auto;
    padding: 40px 0 60px 0;
    .box {
      display: flex;
      align-items: center;
      justify-content: space-between;
      .btn_pn {
        width: 30px;
        height: 30px;
        background: #F0F2F5;
        border-radius: 2px;
        cursor: pointer;
        border: none;
        i {
          display: block;
          font-size: 12px;
          color: #666666;
        }
        span {
          display: none;
        }
      }
      .btn_dis {
        cursor: default;
        i {
          color: #C0C4CC;
        }
      }
      .mob_pager {
        display: none;
      }
      .pager {
        display: flex;
        margin: 0 5px;
        li {
          min-width: 30px;
          height: 30px;
          background: #F0F2F5;
          border-radius: 2px;
          padding: 0 4px;
          margin: 0 5px;
          display: flex;
          align-items: center;
          justify-content: center;
          font-size: 14px;
          color: #666666;
          cursor: pointer;
        }
        .active {
          background: #0064EB;
          color: #FFFFFF;
        }
      }
    }
  }
}
</style>
