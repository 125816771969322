<template>
  <div
    class="introduce"
    :class="[{ benchmark: Object.keys(data).length }]"
    :style="styleObject"
  >
    <div class="bg"></div>
    <div class="introduce-main">
      <p class="title">{{ data.title || title }}</p>
      <p class="desc" :class="[{ hide: !(data && data.desc) }]">
        {{ data.desc || '实现客户高效管理，客勤合理维护，数据智能分析' }}
      </p>
    </div>
  </div>
</template>

<script>
import { getPictures } from '@/api/common/index'
import utils from '@/utils/utils'

export default {
  name: 'introduce',
  props: {
    data: {
      type: Object,
      default: () => ({})
    },
    moduleId: {
      type: Number,
      default: 0
    }
  },
  data() {
    return {
      styleObject: {},
      title: '纸包装行业销售管理工具'
    }
  },
  watch: {
    data() {
      this.getPicturesFun()
    }
  },
  mounted() {
    this.getPicturesFun()
    this.setTitle()
  },
  methods: {
    setTitle() {
      switch (this.moduleId) {
        case 1:
          this.title = '免费试用'
          break
        case 3:
          this.title = '客户案例'
          break
        case 4:
          this.title = '市场活动'
          break
        case 6:
          this.title = '产业课堂'
          break
        case 7:
          this.title = '产品定价'
          break
        case 15:
          this.title = '标杆案例'
          break
      }
    },
    getPicturesFun() {
      if (Object.is(+this.moduleId, 15)) {
        this.data.picture &&
          (this.styleObject = {
            backgroundImage: `url(${utils.setOssUrl(this.data.picture)})`
          })
      } else {
        const params = {
          productId: this.$store.getters.getProductBasicInfo.productId
        }
        getPictures(params).then((res) => {
          if (res.success) {
            for (let i of res.data.list) {
              if (i.moduleType === this.moduleId && i.pictureType === 2) {
                this.styleObject = {
                  backgroundImage: `url(${utils.setOssUrl(i.picture)})`
                }
                return
              }
            }
          }
        })
      }
    }
  }
}
</script>

<style scoped lang="scss">
.introduce {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  height: 422px;
  background-image: url('../../assets/images/bg_default.jpg');
  background-position: center;
  background-size: cover;
  &.benchmark {
    height: 375px;
    background-image: url('../../assets/images/bg_default.png');
    .bg {
      background-color: rgba(0, 0, 0, 0.57);
    }
    .introduce-main {
      width: 80%;
      .title {
        font-size: 40px;
      }
      .desc {
        font-size: 22px;
        line-height: 2;
      }
    }
  }
  .bg {
    position: absolute;
    width: 100%;
    height: 100%;
    background-color: transparent;
    z-index: 1;
  }
  .introduce-main {
    z-index: 2;
    .title {
      font-size: 56px;
      color: #ffffff;
      line-height: 78px;
      font-weight: bold;
    }
    .desc {
      font-size: 24px;
      color: #ffffff;
      line-height: 33px;
      margin-top: 24px;
    }
  }
}
@media (min-width: 751px) and (max-width: 960px) {
  .introduce {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    height: 540px;
    background-image: url('../../assets/images/bg_default.jpg');
    background-position: center;
    background-size: cover;
    &.benchmark {
      .introduce-main {
        .title {
          font-size: 32px;
          line-height: 50px;
        }
        .desc {
          font-size: 16px;
          line-height: 32px;
        }
      }
    }
    .title {
      font-size: 48px;
      color: #ffffff;
      line-height: 67px;
      font-weight: bold;
    }
    .desc {
      font-size: 28px;
      color: #ffffff;
      line-height: 40px;
      margin-top: 24px;
    }
  }
}
@media (min-width: 961px) {
  .introduce {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 630px;
    background-image: url('../../assets/images/bg_default.jpg');
    background-position: center;
    background-size: cover;
    &.benchmark {
      height: 360px;
      .introduce-main {
        width: 1200px;
        padding: 0 30px;
        .title {
          font-size: 36px;
          line-height: 50px;
        }
        .desc {
          font-size: 16px;
          line-height: 32px;
        }
      }
    }
    .title {
      font-size: 48px;
      color: #ffffff;
      line-height: 67px;
    }
    .desc {
      font-size: 28px;
      color: #ffffff;
      line-height: 40px;
      margin-top: 24px;
    }
  }
}
</style>
