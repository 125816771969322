"use strict";

import Vue from 'vue';
import axios from "axios";
import store from "@/store/index.js";
import router from "@/router/index.js";
import logo from "@/assets/images/logo_new.png"

// Full config:  https://github.com/axios/axios#request-config
// axios.defaults.baseURL = process.env.baseURL || process.env.apiUrl || '';
// axios.defaults.headers.common['Authorization'] = AUTH_TOKEN;
// axios.defaults.headers.post['Content-Type'] = 'application/x-www-form-urlencoded';

let config = {
    baseURL: "/officialsite"
    // timeout: 60 * 1000, // Timeout
    // withCredentials: true, // Check cross-site Access-Control
};

function codePath(code) {
    // let msg = ""
    let isNext = true;
    switch (code) {
        case "001802002":
            // msg = "产品不存在";
            isNext = false;
            break;
        case "001802003":
            // msg = "模块已关闭";
            isNext = false;
            break;
    }

    if (!isNext) {
        // 清空vuex
        store.dispatch("setProductBasicInfoFun", {
            productId: "",
            productName: "",
            logoId: "",
            logo:logo,
            position: "",
            description: "",
            domain: "",
            openModule: []
        })
        // 跳转404
        if (router.currentRoute.path !== "/notFound") {
            router.replace({
                path: '/notFound',
                query: {
                    code: code
                }
            });
        }
    }
}

const _axios = axios.create(config);

_axios.interceptors.request.use(
    function (config) {
        // Do something before request is sent
        // 加上productId
        let basicInfo = store.getters.getProductBasicInfo;
        if (config.method === 'post') {
            if (basicInfo.productId) {
                config.data = {
                    productId: basicInfo.productId,
                    ...config.data
                };
            }
        }
        return config;
    },
    function (error) {
        // Do something with request error
        return Promise.reject(error);
    }
);

// Add a response interceptor
_axios.interceptors.response.use(
    function (response) {
        // Do something with response data
        codePath(response.data.code);
        return response.data;
    },
    function (error) {
        // Do something with response error
        return Promise.reject(error);
    }
);

Plugin.install = function (Vue, options) {
    console.log(options);
    Vue.axios = _axios;
    window.axios = _axios;
    Object.defineProperties(Vue.prototype, {
        axios: {
            get() {
                return _axios;
            }
        },
        $axios: {
            get() {
                return _axios;
            }
        },
    });
};

Vue.use(Plugin);

export default Plugin;
export const api = _axios;

