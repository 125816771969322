let routes = [
    //产品定价
    {
        path: '/productPrice',
        name: 'productPrice',
        component: () => import('@/views/productPrice/index.vue'),
    },
];

export default routes;
