import {api} from "../axios";

// 获取产品基本信息
export const getProductBasicInfo = (params) => {
    return api({
        method: 'post',
        url: '/product/getProductBasicInfo.do',
        data: params
    });
};

// 获取操作手册
export const getProductManual = (params) => {
    return api({
        method: 'post',
        url: '/handbook/getProductManual.do',
        data: params
    });
};

// 获取侧边栏信息
export const getProductSideBar = (params) => {
    return api({
        method: 'post',
        url: '/product/getProductSideBar.do',
        data: params
    });
};

// 查询所有已发布的产品线
export const getReleaseProductList = (params) => {
    return api({
        method: 'post',
        url: '/product/getReleaseProductList.do',
        data: params
    });
};

// 获取产品定价
export const getProductPricing = (params) => {
    return api({
        method: 'post',
        url: '/firmPrice/getProductPricing.do',
        data: params
    });
};

// 获取模块配置图片
export const getPictures = (params) => {
    return api({
        method: 'post',
        url: '/product/getProductPictures.do',
        data: params
    });
};

// 获取除详情页之外的TDK配置
export const getTdkList = (params) => {
    return api({
        method: 'post',
        url: '/product/getTdkList.do',
        data: params
    });
};

// 获取底部栏
export const getProductBottomBar = (params) => {
    return api({
        method: 'post',
        url: '/product/getProductBottomBar.do',
        data: params
    });
};

// 获取系统时间
export const getSystemDate = (params) => {
    return api({
        method: 'post',
        url: '/official/getSystemDate.do',
        data: params
    });
};
