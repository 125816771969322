let routes = [
    //客户案例-列表
    {
        path: '/example/list',
        name: 'example',
        component: () => import('@/views/example/list.vue'),
    },
    //客户案例-详情
    {
        path: '/example/details',
        name: 'exampleDetails',
        component: () => import('@/views/example/details.vue'),
    },
];

export default routes;
