(function (doc, win) {
    let baseSize = 100;
    let docEl = doc.documentElement,
        resizeEvt = "orientationchange" in window ? "orientationchange" : "resize",
        recalc = function () {
            if (docEl.clientWidth <= 750) {
                const scale = docEl.clientWidth / 750;
                docEl.style.fontSize = (baseSize * Math.min(scale, 2)) + 'px';
            } else if (docEl.clientWidth > 750 && docEl.clientWidth <= 960) {
                const scale = docEl.clientWidth / 960;
                docEl.style.fontSize = (baseSize * Math.min(scale, 2)) + 'px';
            } else if (docEl.clientWidth > 960 && docEl.clientWidth <= 1366) {
                const scale = docEl.clientWidth / 1366;
                docEl.style.fontSize = (baseSize * Math.min(scale, 2)) + 'px';
            } else {
                docEl.style.fontSize = '100px';
            }
            doc.getElementById("app").style.width = "auto";
        };
    if (!doc.addEventListener) return;
    win.addEventListener(resizeEvt, recalc, false);
    doc.addEventListener("DOMContentLoaded", recalc, false);
})(document, window);
