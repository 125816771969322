<template>
  <div class="details">
    <Crumbs :config="crumbsConfig"/>
    <div class="det_content">
      <Substance :details="details"/>
      <Recommend :details="details"/>
    </div>
    <Advert :adsPojo="details.adsPojo" v-if="details.adsPojo!==null" />
  </div>
</template>

<script>
import Crumbs from "./crumbs";
import Substance from "./substance";
import Recommend from "./recommend";
import { getCaseDetails, getCooperativeStoryInfo } from "@/api/product.js";
import Advert from "./advert";

export default {
  components: {Crumbs, Substance, Recommend, Advert},
  props: {
    crumbsConfig: {
      type: Array,
      default: () => {
        return [];
      }
    }
  },
  data() {
    return {
      details: {},
      id: "",
      moduleType: "",
      metaInfo: {}
    };
  },
  metaInfo() {
    return this.metaInfo;
  },
  created() {
    this.id = this.$route.query.id;
    this.moduleType = this.$route.query.moduleType;
    this.getCaseDetailsFun();
  },
  methods: {
    getCaseDetailsFun() {
      let params = {
        moduleId: this.id,
        configType: this.moduleType
      };
      // 针对标杆案例/通用区分
      const api =
        Object.is(+this.moduleType, 15) || Object.is(+this.moduleType, 16)
          ? getCooperativeStoryInfo
          : getCaseDetails
      api(params).then((res) => {
        if (res.success) {
          this.details = res.data;
          res.data.tdkdata = !res.data.tdkdata ? {} : res.data.tdkdata
          this.metaInfo = {
            title: res.data.tdkdata.title,
            meta: [
              {
                name: "keywords",
                content: res.data.tdkdata.keywords ? res.data.tdkdata.keywords.join(",") : ""
              },
              {
                name: "description",
                content: res.data.tdkdata.description
              }
            ]
          };
        } else {
          this.$router.replace({
            path: '/notFound'
          });
        }
      });
    }
  }
};
</script>

<style scoped lang="scss">
.details {
  background-color: #FFFFFF;
  .det_content {
    padding: 40px 60px 80px;
  }
}
@media (min-width: 751px) and (max-width: 960px) {
  .details {
    background-color: #FFFFFF;
    .det_content {
      padding: 60px;
    }
  }
}
@media (min-width: 961px) {
  .details {
    .det_content {
      padding: 40px 0 80px 0;
      display: flex;
      width: 1200px;
      margin: 0 auto;
    }
  }
}
</style>
