import $ from "jquery";

export default new class Utils {

    async copy(text) {
        let target = document.createElement('input'); //创建input节点
        target.value = text; // 给input的value赋值
        document.body.appendChild(target); // 向页面插入input节点
        target.select(); // 选中input
        try {
            await document.execCommand('Copy'); // 执行浏览器复制命令
            await target.remove(); // 删除节点
        } catch {
            console.log('复制失败');
        }
    }

    isDingtalk() {
        const u = navigator.userAgent.toLowerCase();
        return u.match(/DingTalk/i) == "dingtalk";
    }

    getActivityDate(val, separator = "/") {
        if (!val) {
            return val;
        }
        val = val.replace(/-/g, "/");
        let date = new Date(val);
        let year = date.getFullYear();
        let month = date.getMonth() + 1;
        let days = date.getDate();
        return `${year}${separator}${month}${separator}${days}`;
    }

    isBigScreen() { // 判断全面屏手机
        let result = false;
        let u = navigator.userAgent;
        let isIOS = !!u.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/);
        const rate = window.screen.height / window.screen.width;
        let limit = window.screen.height == window.screen.availHeight ? 1.8 : 1.65; // 临界判断值
        if (rate > limit) {
            result = true;
        }
        return result && isIOS;
    }

    prohibitZoom() {  // 禁止浏览器的缩放功能
        window.addEventListener('mousewheel', function (event) {
            if (event.ctrlKey === true || event.metaKey) {
                event.preventDefault();
            }
        }, {passive: false});

        //firefox
        window.addEventListener('DOMMouseScroll', function (event) {
            if (event.ctrlKey === true || event.metaKey) {
                event.preventDefault();
            }
        }, {passive: false});
    }

    // 动画效果js
    handleScroll(el, inAniamte, outAniamte, size = 100) {
        el.each((i, e) => {
            // 滚动的值
            let scrollTopE = $(document).scrollTop();
            // 当前元素距离顶部的距离
            let bannerTop = $(e).offset().top;
            // 当前元素的高度
            // let bannerHeight = $(e).outerHeight();
            // 屏幕的高度
            let screenHeight = $(window).height();
            // 屏幕宽度
            let screenWidth = $(window).width();
            // 移动端和pad端不做动态效果
            if (screenWidth < 960) {
                return false;
            }
            if ((bannerTop - screenHeight) < (scrollTopE - size)) {
                inAniamte(i, $(e));
            } else {
                outAniamte(i, $(e));
            }
        });
    }
    setOssUrl(url) {
        if(!url){
            return ""
        } else if (url.includes("http://") || url.includes("https://")) {
            return url;
        } else if (url.includes("/fileserver/")) {
            return "//download.djcps.com/" + url;
        } else {
            return url;
        }
    }
};
