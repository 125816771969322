import {api} from "./axios";

// 获取产品详情(首页)
export const getProductDetailById = (params) => {
    return api({
        method: 'post',
        url: '/product/getProductDetailById.do',
        data: params
    });
};


// 列表查询-公用
export const getCaseList = (params) => {
    return api({
        method: 'post',
        url: '/customerCase/getCaseList.do',
        data: params
    });
};

// 详情查询-公用
export const getCaseDetails = (params) => {
    return api({
        method: 'post',
        url: '/customerCase/getCaseDetails.do',
        data: params
    });
};

// 查询常见问题分类
export const selectClassify = (params) => {
    return api({
        method: 'post',
        url: '/problem/selectClassifyList.do',
        data: params
    });
};

// 常见问题查询
export const selectProblemList = (params) => {
    return api({
        method: 'post',
        url: '/problem/selectProblemList.do',
        data: params
    });
};

// 常见问题详情
export const getProblemDetails = (params) => {
    return api({
        method: 'post',
        url: '/problem/getProblemDetails.do',
        data: params
    });
};

// 获取验证码
export const sendPhoneCode = (params) => {
    return api({
        method: 'post',
        url: '/product/sendPhoneCode.do',
        data: params
    });
};

// 申请免费试用
export const applyUse = (params) => {
    return api({
        method: 'post',
        url: '/product/applyUse.do',
        data: params
    });
};

// 标杆案例列表查询
export const getSupplierInfoList = (params) => {
    return api({
        method: 'post',
        url: '/customerCase/findSupplierInfoList.do',
        data: params
    });
};

// 标杆案例-合作故事列表查询
export const getSupplierStoryInfoList = (params) => {
    return api({
      method: 'post',
      url: '/customerCase/findSupplierStoryInfo.do',
      data: params
    })
};

// 标杆案例-合作故事详情
export const getCooperativeStoryInfo = (params) => {
    return api({
        method: 'post',
        url: '/customerCase/getCooperativeStoryInfo.do',
        data: params
    });
};
