import Vue from 'vue';
import VueRouter from 'vue-router';
import NotFound from "../views/other/notFound";
Vue.use(VueRouter);

import productCenter from "./product";
import industryClass from "./industryClass";
import marketActivity from "./marketActivity";
import example from "./example";
import benchmarkExample from "./benchmarkExample";
import productPrice from "./productPrice";
import userService from "./userService";


const baseRoutes = [
    {
        path: "/notFound",
        component: NotFound
    },
    {
        path: "*",
        redirect: "/notFound"
    }
];

const routes = baseRoutes.concat(
  productCenter,
  industryClass,
  marketActivity,
  example,
  benchmarkExample,
  productPrice,
  userService
)

const router = new VueRouter({
    mode: 'history',
    // base: process.env.BASE_URL,
    routes
});

router.afterEach(() => {
    document.body.scrollTop = 0;
    document.documentElement.scrollTop = 0;
});
export default router;
