<template>
  <div class="inputbox" :class="{error_input:!isNext}">
    <input v-model="value" :type="type" :placeholder="placeholder" :maxlength="max" @input="checkinput"
           @blur="blurInput">
    <p>{{ errorMsg }}</p>
  </div>
</template>

<script>
export default {
  model: {
    prop: 'value',
    event: 'input'
  },
  props: {
    placeholder: {
      type: String,
      default: ""
    },
    max: {
      type: Number,
      default: null
    },
    type: {
      type: String,
      default: "text"
    },
    regulars: {
      type: Array,
      default: () => {
        return [];
      }
    },
    rules: {
      type: Array,
      default: () => {
        return [];
      }
    }
  },
  data() {
    return {
      value: "",
      isNext: true,
      errorMsg: ""
    };
  },
  created() {
  },
  methods: {
    checkinput() {
      for (let i of this.regulars) {
        this.value = this.value.replace(i, '');
      }
      this.$emit("input", this.value);
    },
    blurInput() {
      this.$emit("blur", this.value);
      this.checkRules();
    },
    checkRules() {
      let isOk = true, errorMsg = "";
      for (let i of this.rules) {
        if (i.required && this.value.match(/^[ ]*$/)) {
          isOk = false;
          errorMsg = i.message;
          break;
        }
        if (i.validator && typeof i.validator === "function") {
          isOk = i.validator(this.value);
          if (!isOk) {
            errorMsg = i.message;
            break;
          }
        }
      }
      this.isNext = isOk;
      this.errorMsg = errorMsg;
      return isOk;
    },
    getRulesValue() {
      let isOk = true;
      for (let i of this.rules) {
        if (i.required && this.value.match(/^[ ]*$/)) {
          isOk = false;
          break;
        }
        if (i.validator && typeof i.validator === "function") {
          isOk = i.validator(this.value);
        }
      }
      return isOk;
    }
  }
};
</script>

<style scoped lang="scss">
.inputbox {
  position: relative;
  input {
    width: 100%;
    height: 80px;
    background: #FFFFFF;
    border: 1px solid #CCCCCC;
    padding: 0 24px;
    box-sizing: border-box;
    font-size: 26px;
    position: relative;
  }
  p {
    display: none;
    font-size: 24px;
    color: #DB0010;
    position: absolute;
    top: 80px;
    left: 0;
  }
}
.error_input {
  input {
    border: 1px solid #DB0010;
  }
  p {
    display: block;
  }
}
@media (min-width: 751px) and (max-width: 960px) {
  .inputbox {
    position: relative;
    input {
      width: 100%;
      height: 48px;
      background: #FFFFFF;
      border: 1px solid #CCCCCC;
      padding: 0 24px;
      box-sizing: border-box;
      font-size: 14px;
      position: relative;
    }
    p {
      display: none;
      font-size: 12px;
      color: #DB0010;
      position: absolute;
      top: 50px;
      left: 0;
    }
  }
  .error_input {
    input {
      border: 1px solid #DB0010;
    }
    p {
      display: block;
    }
  }
}
@media (min-width: 961px) {
  .inputbox {
    position: relative;
    input {
      width: 100%;
      height: 48px;
      background: #FFFFFF;
      border: 1px solid #CCCCCC;
      padding: 0 13px;
      box-sizing: border-box;
      font-size: 14px;
      position: relative;
    }
    p {
      display: none;
      font-size: 12px;
      color: #DB0010;
      position: absolute;
      top: 48px;
      left: 0;
    }
  }
  .error_input {
    input {
      border: 1px solid #DB0010;
    }
    p {
      display: block;
    }
  }
}
</style>
