let routes = [
    //标杆案例-列表
    {
        path: '/benchmarkExample',
        name: 'benchmarkExample',
        component: () => import('@/views/benchmarkExample'),
    },
    //标杆案例-合作故事
    {
        path: '/benchmarkExample/story',
        name: 'benchmarkStory',
        component: () => import('@/views/benchmarkExample/story'),
    },
];

export default routes;
