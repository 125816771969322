// 1-免费试用, 2-服务保障, 3-客户案例, 4-市场活动, 5-常见问题, 6-产业课堂, 7-产品定价, 8-客户反馈, 9-侧边栏, 10-更多产品, 15-标杆案例
export default {
  freeUse: {
    moduleType: 1,
    moduleName: '免费试用',
    isMenu: false
  },
  service: {
    moduleType: 2,
    moduleName: '服务保障',
    isMenu: false
  },
  example: {
    moduleType: 3,
    moduleName: '客户案例',
    isMenu: true
  },
  marketActivity: {
    moduleType: 4,
    moduleName: '市场活动',
    isMenu: true
  },
  question: {
    moduleType: 5,
    moduleName: '常见问题',
    isMenu: false
  },
  industryClass: {
    moduleType: 6,
    moduleName: '产业课堂',
    isMenu: true
  },
  productPrice: {
    moduleType: 7,
    moduleName: '产品定价',
    isMenu: true
  },
  feedback: {
    moduleType: 8,
    moduleName: '客户反馈',
    isMenu: false
  },
  sidebar: {
    moduleType: 9,
    moduleName: '侧边栏',
    isMenu: false
  },
  more: {
    moduleType: 10,
    moduleName: '更多产品',
    isMenu: false
  },
  footer: {
    moduleType: 11,
    moduleName: '底部栏',
    isMenu: false
  },
  benchmarkExample: {
    moduleType: 15,
    moduleName: '标杆案例',
    isMenu: true
  },
  benchmarkStory: {
    moduleType: 16,
    moduleName: '合作故事',
    isMenu: false
  }
}
