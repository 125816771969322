<template>
  <div class="footer js_footer"
       :class="{footer_padding:isMobileFooter,bigScreen:isBigScreen&&isMobileFooter}">
    <div class="footer_box">
      <div class="center_logo">
        <div class="center_box">
          <div class="center">
            <p class="title">
              <span>产品中心</span>
              <i class="font_family icon-common_arrow_right"></i>
            </p>
            <ul class="content">
              <li v-for="item in productList" :key="item.productId">
                <a :href="`http://${item.domain}?source=footer`" target="_blank">{{ item.productName }}</a>
              </li>
            </ul>
          </div>
          <div class="center news">
            <p class="title">
              <span>用户服务</span>
              <i class="font_family icon-common_arrow_right"></i>
            </p>
            <ul class="content">
              <li>
                <router-link to="/userService/question" v-if="basicInfo.openModule.includes(5)">常见问题</router-link>
              </li>
              <li><a @click="manualClick">操作手册</a></li>
            </ul>
          </div>
          <div class="center about" v-if="basicInfo.openModule.includes(7)">
            <p class="title">
              <span>产品定价</span>
              <i class="font_family icon-common_arrow_right"></i>
            </p>
            <ul class="content">
              <li v-for="(item,index) in priceList" :key="item.pricingId">
                <router-link :to="{path:'/productPrice',query: {id: index}}">{{ item.releaseName }}</router-link>
              </li>
            </ul>
          </div>
          <div class="other_box">
            <!-- <div class="other" v-if="basicInfo.openModule.includes(3)">
              <router-link to="/example/list" class="o_title">
                <span>客户案例</span>
                <i class="font_family icon-common_arrow_right"></i>
              </router-link>
            </div> -->
            <div class="other" v-if="basicInfo.openModule.includes(15)">
              <router-link to="/benchmarkExample" class="o_title">
                <span>标杆案例</span>
                <i class="font_family icon-common_arrow_right"></i>
              </router-link>
            </div>
            <div class="other" v-if="basicInfo.openModule.includes(4)">
              <router-link to="/marketActivity/list" class="o_title">
                <span>市场活动</span>
                <i class="font_family icon-common_arrow_right"></i>
              </router-link>
            </div>
            <div class="other" v-if="basicInfo.openModule.includes(6)">
              <router-link to="/industryClass/list" class="o_title">
                <span>产业课堂</span>
                <i class="font_family icon-common_arrow_right"></i>
              </router-link>
            </div>
          </div>

        </div>
        <div class="logo_code" v-if="basicInfo.openModule.includes(11)">
          <p class="logo_box">
            <img class="logo" :src="basicInfo.logo" alt="">
          </p>
          <p class="sweep" v-if="bottomBar.scan===1">扫一扫</p>
          <div class="code_box" v-if="bottomBar.scan===1">
            <div class="code" v-if="bottomBar.pictureOneId && bottomBar.descriptionOne">
              <img :src="utils.setOssUrl(bottomBar.pictureOne)" alt="">
              <p>{{ bottomBar.descriptionOne }}</p>
            </div>
            <div class="code" v-if="bottomBar.pictureTwoId || bottomBar.descriptionTwo">
              <img v-if="bottomBar.pictureTwoId" :src="utils.setOssUrl(bottomBar.pictureTwo)" alt="">
              <p>{{ bottomBar.descriptionTwo }}</p>
            </div>
          </div>
        </div>
      </div>
      <div class="copyright">
        <p class="copy">版权所有 © 2011-{{ year }} 浙ICP备15023330号-3</p>
        <p class="record">
          <img src="@/assets/images/footer_ba.jpg" alt="">
          <span>浙公网安备33030402000738号</span>
        </p>
      </div>
      <MobileFooter v-if="isMobileFooter" :sidebarInfo="bottomBar"/>
    </div>
  </div>
</template>

<script>
import $ from "jquery";
import MobileFooter from "./mobileFooter";
import {
  getReleaseProductList,
  getProductManual,
  getProductPricing,
  getProductBottomBar,
  getSystemDate
} from "@/api/common/index.js";
import utils from "@/utils/utils";

export default {
  name: "layoutFooter",
  components: {MobileFooter},
  props: {
    info: {
      type: Object
    }
  },
  data() {
    return {
      productList: [],
      priceList: [],
      link: "",
      sideBarInfo: {
        sideBar: {
          downloadNow: 0,
          downloadPic: ""
        }
      },
      path: "",
      isBigScreen: false,
      isOpenBottom: false,
      bottomBar: {},
      year: "",
      utils
    };
  },
  watch: {
    basicInfo() {
      this.getProductManualFun();
      this.getProductPricingFun();
    },
    info() {
      this.sideBarInfo = this.info;
    },
    $route(to) {
      this.path = to.path;
    }
  },
  computed: {
    basicInfo() {
      return this.$store.getters.getProductBasicInfo;
    },
    isMobileFooter() {
      return (this.bottomBar.onlineConsult === 1 || this.bottomBar.freeUse === 1 || this.bottomBar.tryNow === 1 || this.bottomBar.contactUs === 1) && this.path !== '/product/freeUse';
    }
  },
  created() {
    this.getIsBigScreen();
    this.getProductBottomBarFun();
    this.getYear();
  },
  mounted() {
    this.setAnimation();
    this.getReleaseProductListFun();
    this.getProductManualFun();
    this.getProductPricingFun();
  },
  methods: {
    getYear() {
      getSystemDate().then((res) => {
        if (res.success) {
          this.year = res.data.systemDate;
        }
      });
    },
    getProductBottomBarFun() {
      getProductBottomBar().then((res) => {
        if (res.success) {
          this.isOpenBottom = res.data.isOpen;
          this.bottomBar = res.data.bottomBar;
        }
      });
    },
    getIsBigScreen() {
      window.onresize = () => {
        this.isBigScreen = utils.isBigScreen();
      };
      this.isBigScreen = utils.isBigScreen();
    },
    getProductManualFun() {
      if (this.basicInfo.openModule.includes(9)) {
        let params = {
          productId: this.basicInfo.productId
        };
        getProductManual(params).then((res) => {
          if (res.success && res.data) {
            this.link = res.data.link;
          }
        });
      }
    },
    getProductPricingFun() {
      if (this.basicInfo.openModule.includes(7)) {
        let params = {
          productId: this.basicInfo.productId
        };
        getProductPricing(params).then((res) => {
          if (res.success) {
            this.priceList = res.data.contentList;
          }
        });
      }
    },
    manualClick() {
      if (this.link) {
        window.open(this.link);
      } else {
        if (this.$route.path !== "/userService/manualNot") {
          this.$router.push({
            path: '/userService/manualNot'
          });
        }
      }
    },
    setAnimation() {
      $(".js_footer a").click(() => {
        $(".js_footer .title").children("i").removeClass("icon-common_arrow_down").addClass("icon-common_arrow_right");
        $(".js_footer .content").hide();
      });
      $(".js_footer .title").click((e) => {
        let w = $(window).width();
        if (w > 960) {
          return false;
        }
        if ($(e.currentTarget).children("i").hasClass("icon-common_arrow_right")) {
          $(e.currentTarget).children("i").addClass("icon-common_arrow_down").removeClass("icon-common_arrow_right");
        } else {
          $(e.currentTarget).children("i").addClass("icon-common_arrow_right").removeClass("icon-common_arrow_down");
        }
        $(e.currentTarget).siblings(".content").toggle(200);
      });
    },
    getReleaseProductListFun() {
      getReleaseProductList().then((res) => {
        if (res.success) {
          this.productList = res.data.list;
        }
      });
    }
  }
};
</script>

<style scoped lang="scss">
.footer {
  width: 100%;
  padding: 60px;
  background: #FFFFFF;
  border-top: 1px solid #F5F5F5;
  .center_logo {
    .center_box {
      .center {
        border-bottom: 1px solid #F5F5F5;
        .title {
          display: flex;
          align-items: center;
          justify-content: space-between;
          height: 72px;
          span {
            font-size: 28px;
            color: #333333;
          }
          i {
            font-size: 24px;
          }
        }
        .content {
          display: none;
          margin-top: 16px;
          li {
            margin-bottom: 16px;
            a {
              font-size: 26px;
              color: #666666;
              cursor: pointer;
            }
          }
        }
      }
      .news {
      }
      .about {
      }
      .other_box {
        .other {
          border-bottom: 1px solid #F5F5F5;
          .o_title {
            display: flex;
            align-items: center;
            justify-content: space-between;
            height: 72px;
            span {
              font-size: 28px;
              color: #333333;
            }
            i {
              font-size: 24px;
            }
          }
        }
      }
    }
    .logo_code {
      margin-top: 3px;
      .logo_box {
        display: flex;
        justify-content: center;
        .logo {
          width: 240px;
          height: 121px;
        }
      }
      .sweep {
        display: none;
      }
      .code_box {
        margin-top: 3px;
        display: flex;
        justify-content: center;
        .code {
          margin-left: 73px;
          display: flex;
          flex-direction: column;
          align-items: center;
          &:first-child {
            margin-left: 0;
          }
          img {
            width: 146px;
            height: 150px;
          }
          p {
            margin-top: 16px;
            font-size: 24px;
            color: #666666;
          }
        }
      }
    }
  }
  .copyright {
    .copy {
      margin-top: 60px;
      font-size: 24px;
      color: #666666;
      line-height: 33px;
    }
    .record {
      display: flex;
      align-items: center;
      margin-top: 16px;
      img {
        width: 18px;
        height: 20px;
      }
      span {
        font-size: 24px;
        color: #666666;
        line-height: 33px;
        margin-left: 10px;
      }
    }
  }
}
a {
  &:active {
    color: #0064EB !important;
  }
}
.o_title span {
  &:active {
    color: #0064EB !important;
  }
}
.footer_padding {
  padding-bottom: 120px;
}
.bigScreen {
  padding-bottom: 180px;
}
@media (min-width: 750px) and (max-width: 960px) {
}
@media (min-width: 961px) {
  .footer_padding {
    padding-bottom: 0;
  }
  .bigScreen {
    padding-bottom: 0;
  }
  a {
    &:hover {
      color: #0064EB !important;
    }
  }
  .o_title span {
    &:hover {
      color: #0064EB !important;
    }
  }
  .footer {
    width: 100%;
    padding: 0;
    background: url("../../assets/images/footer_bg.png");
    background-size: cover;
    .footer_box {
      width: 1200px;
      margin: 0 auto;
      padding: 60px 0 25px 0;
    }
    .center_logo {
      display: flex;
      justify-content: space-between;
      .center_box {
        display: flex;
        .center {
          border-bottom: none;
          margin-left: 127px;
          &:first-child {
            margin-left: 0;
          }
          .title {
            display: flex;
            align-items: center;
            justify-content: space-between;
            height: auto;
            span {
              font-size: 16px;
              color: #333333;
            }
            i {
              display: none;
            }
          }
          .content {
            display: block !important;
            margin-top: 30px;
            li {
              margin-bottom: 10px;
              font-size: 14px;
              a {
                font-size: 14px;
                color: #666666;
              }
            }
          }
        }
        .news {
        }
        .about {
        }
        .other_box {
          display: flex;
          flex-direction: column;
          margin-left: 110px;
          .other {
            border-bottom: none;
            margin-top: 30px;
            &:first-child {
              margin-top: 0;
            }
            .o_title {
              display: flex;
              align-items: center;
              justify-content: space-between;
              height: auto;
              span {
                font-size: 16px;
                color: #333333;
              }
              i {
                display: none;
              }
            }
          }
        }
      }
      .logo_code {
        margin-top: 0;
        .logo_box {
          display: none;
        }
        .sweep {
          display: block;
          font-size: 16px;
          color: #333333;
          line-height: 21px;
        }
        .code_box {
          margin-top: 38px;
          display: flex;
          .code {
            margin-left: 56px;
            img {
              width: 112px;
              height: 112px;
            }
            p {
              font-size: 13px;
              color: #666666;
              line-height: 17px;
              margin-top: 7px;
              text-align: center;
            }
          }
        }
      }
    }
    .copyright {
      display: flex;
      align-items: center;
      justify-content: center;
      margin-top: 10px;
      .copy {
        margin-top: 0;
        font-size: 12px;
        color: #333333;
        line-height: 16px;
      }
      .record {
        display: flex;
        align-items: center;
        margin-top: 0;
        margin-left: 169px;
        img {
          width: 18px;
          height: 20px;
        }
        span {
          font-size: 12px;
          color: #333333;
          line-height: 16px;
          margin-left: 5px;
        }
      }
    }
  }
}
</style>
