let routes = [
    //产品页-首页
    {
        path: '/',
        name: 'productIndex',
        component: () => import('@/views/product/index.vue'),
    },
    //产品页-免费试用
    {
        path: '/product/freeUse',
        name: 'productFreeUse',
        component: () => import('@/views/product/freeUse.vue'),
    },
];

export default routes;
