let routes = [
    //产业课堂-列表
    {
        path: '/industryClass/list',
        name: 'industryClass',
        component: () => import('@/views/industryClass/list.vue'),
    },
    //产业课堂-详情
    {
        path: '/industryClass/details',
        name: 'industryClassDetails',
        component: () => import('@/views/industryClass/details.vue'),
    },
];

export default routes;
