import store from "@/store/index";

const metaInfoMixins = {
    metaInfo() {
        let pathname = window.location.pathname, metaInfo = {};
        let tdk = store.getters.getTdk;
        for (let i of tdk) {
            if (i.path === pathname || i.path + "/" === pathname) {
                metaInfo = i.metaInfo;
            }
        }
        return metaInfo;
    },
};

export default metaInfoMixins;
