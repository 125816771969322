import Vue from 'vue';
import Vuex from 'vuex';
import createPersistedState from "vuex-persistedstate";
import logo from "@/assets/images/logo_new.png"

Vue.use(Vuex);

export default new Vuex.Store({
    state: {
        productBasicInfo: {
            productId: "",
            productName: "",
            logoId: "",
            logo:logo,
            position: "",
            description: "",
            domain: "",
            openModule: []
        },
        tdk:[]
    },
    getters: {
        getProductBasicInfo(state) {
            return state.productBasicInfo;
        },
        getTdk(state) {
            return state.tdk;
        },
    },
    mutations: {
        setProductBasicInfo(state, obj) {
            state.productBasicInfo = obj;
        },
        setTdk(state, obj) {
            state.tdk = obj;
        }
    },
    actions: {
        setProductBasicInfoFun(context, obj) {
            context.commit('setProductBasicInfo', obj);
        },
        setTdkFun(context, obj) {
            context.commit('setTdk', obj);
        }
    },
    modules: {},
    plugins: [createPersistedState({storage: window.sessionStorage})]
});
