import sensors from 'sa-sdk-javascript';

function getServerUrl() {
    let test = ["yi.cpsol.net","yizhixiang.cpsol.net","yizhipei.cpsol.net","yidanlai.cpsol.net"], pro = ["yi.djcps.com","yizhixiang.djcps.com","yizhipei.djcps.com","yidanlai.djcps.com"];
    let hostname = window.location.hostname;
    var serverUrl = "";
    if (pro.includes(hostname)) {
        serverUrl = "https://shence.olcps.com:18106/sa?project=production";
    } else if (test.includes(hostname)) {
        serverUrl = "https://shence.djcps.com:18106/sa?project=default";
    } else {
        serverUrl = "http://47.111.95.29:8106/sa?project=default";
    }
    return serverUrl;
}

sensors.init({
    server_url: getServerUrl(),
    is_track_single_page: true,
    show_log: false,
    send_type: 'beacon',
    heatmap: {
        clickmap: 'default',
        scroll_notice_map: 'default'
    }
});
sensors.quick('autoTrack');

export default sensors
